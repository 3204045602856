import MainLayout from "@/components/layout/admin/MainLayout.vue";
import {
  required,
  // maxLength
} from "vuelidate/lib/validators";
import axios from 'axios';
import Loading from "vue-loading-overlay";
import CONFIG from "@/assets/js/config.js";
import Select2Multiple from 'v-select2-multiple-component';

export default {
  name: "ferryPickupEdit",
  components: {
    MainLayout,
    Loading,
    Select2Multiple,
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      pickupData: [],
      routeArr: [], 
      pointArr: [],
      submitted: false,
      disabled: false,
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
    };
  },

  validations: {
    pickupData: {
      user_name: {
        required,
      },
      route_id: {
        required
      },
      route_point_id: {
        required
      }
    },
  },

  methods: {
    getRoute() {
      axios.get('route/getRouteForRoutePoint', {
        params: {
          campus_id: this.$store.state.user.campus_id
        }
      })
        .then(res => {
          this.isLoading = false;
          this.routeArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    routePointByRoute(id) {
      axios.get(`routePoint/getPointByRoute/${id}`, { params: { campus_id: this.$store.state.user.campus_id } })
        .then((res) => {
          if (res.data) {
            this.pointArr = res.data.data;
            this.disabled = false;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getDetail() {
      axios.get(`ferryPickup/detail/${this.$route.params.id}`, {
        params: {
          campus_id: this.$store.state.user.campus_id,
          user_type: this.$route.params.status
        }
      })
        .then((res) => {
          if (res.data) {
            this.pickupData = res.data.data;
            if (this.pickupData.route_id != null) {
              this.routePointByRoute(this.pickupData.route_id);
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      if (this.pickupData.pickup_id == null) {
        this.pickupData.status = 'create';
      } else {
        this.pickupData.status = 'update'
      }
      this.disabled = true;
      this.pickupData.loginId = this.$store.state.user.id;
      axios.post(`ferryPickup/createAndUpdate/${this.$route.params.id}`, this.pickupData)
        .then(res => {
          if (res.data) {
            this.$notification.success("Ferry Pickup Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({ name: "ferryPickupList" });
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getRoute();
    this.getDetail();
  },

};
