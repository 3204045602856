import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";

export default {
  name: "AppFormList",
  components: {
    MainLayout,
    AdvancedLaravelVuePaginate,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_FOUND,
      appTypeText: CONFIG.APPLICATION.TYPE_TXT,
      appUrl: CONFIG.APPLICATION_URL.replace(/\/$/, '') + '/',
      isLoading: false,
      appFormsData : {},
    };
  },

  methods: {
    getAppForms(page = 1) {
      axios.get('appForm/list?page=' + page, {
        params: {
            campus_id: this.$store.state.user.campus_id,
        }
    })
            .then(res => {
              this.appFormsData = res.data;
              this.isLoading = false;
            }).catch((error) => {
              if (error.response && error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.isLoading = false;
            })
    },

    confirmDelete(appFormId) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteAppForm(appFormId);
      }
    },

    deleteAppForm(appFormId) {
      axios.delete("appForm/delete/" + appFormId)
      .then(response => {
        if (response.data.success == true) {
            this.isLoading = true;
            this.getAppForms();
          }
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
        });
    },

    downloadApplicants(appFormId) {
      this.isLoading = true;
      axios.get('appForm/downloadApplicants/' + appFormId, {
         responseType: 'blob'
      })
        .then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileURL = window.URL.createObjectURL(blob);
            
            // Create a link element to trigger the download
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            const date = new Date();
            fileLink.setAttribute('download', `applicant_list_${date.toISOString().slice(0, 10)}.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
          this.isLoading = false;
        });
    }

  },

  mounted() {
    this.isLoading = true;
    this.getAppForms();
  },
};
