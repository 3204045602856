import Loading from 'vue-loading-overlay';
import Select2Multiple from "v-select2-multiple-component";
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  required,
  maxLength,
  helpers
} from "vuelidate/lib/validators";
import axios from "axios";

const inputTypeVal = await Object.entries(CONFIG.INPUT_TYPES_VAL)
                            .filter(([key]) => key !== 'FILE')
                            .reduce((obj, [key, value]) => {
                              obj[key] = value;
                              return obj;
                            }, {});

const inInputType = helpers.withParams(
  { type: "inInputType" },
  value => {
    return Object.values(inputTypeVal).includes(Number(value))
  }
);

export default {
  name: "FormItemModal",
  components: {
    Loading,
    Select2Multiple,
  },

  props: {
    modalId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'Form Item'
    },
    status: {
      type: String,
      required: true,
    },
    inputTypeArr: {
      required: true
    },
    formItemUpdate: {
      type: Object,
      required: false,
    }
  },

  data() {
    return {
      inputTypeVal: inputTypeVal,
      errors: [],
      submitted: false,
      disabled: false,
      isLoading: false,
      generalError: '',
      formItemData: {
        input_type: 0,
        label: '',
        placeholder: '',
        choices: [
          {label: ''},
        ]
      }
    };
  },

  watch: {
    formItemUpdate: {
      immediate: false,
      handler(newVal) {
        if (this.status == 'Update' && newVal) {
          this.cloneUpdateData(newVal);
        }
      }
    }
  },

  validations: {
    formItemData: {
      input_type: {
        required,
        inInputType,
      },
      label: {
        required,
        maxLength: maxLength(255),
      },
      placeholder: {
        maxLength: maxLength(255),
      },
    }
  },

  methods: {
    addField() {
      let lastField = this.formItemData.choices[this.formItemData.choices.length - 1];
      if (lastField.label != '') {
        this.formItemData.choices.push({ label: ''});
      }
    },

    removeField(index) {
      if (this.formItemData.choices.length > 1) {
        this.formItemData.choices.splice(index, 1);
      } else {
        this.formItemData.choices[index].label = '';
      }
    },

    reset(type = null) {
      if (type == null) {
        this.formItemData.label = '';
        this.formItemData.placeholder = '';
        this.formItemData.choices = [{label: ''}];
      } else {
        this.formItemData = {
          input_type: 0,
          label: '',
          placeholder: '',
          choices: [
            {label: ''},
          ]
        };
        if (this.status == 'Update') {
          this.cloneUpdateData(this.formItemUpdate);
        }
      }
      this.submitted = false;
    },

    validateFields() {
      this.errors = Array(this.formItemData.choices.length).fill({ label: '' });
      this.generalError = '';
      this.deleteBlankChoice();
      if (this.formItemData.choices.length == 0) {
        this.generalError = 'At least one item choice is required.';
        this.formItemData.choices.push({ label: '' });
        return false;
      }

      let isValid = true;
      const seenLabels = new Set();

      this.formItemData.choices.forEach((field, index) => {
        const fieldErrors = { label: '' };
        
        // Validate key
        if (!field.label.trim()) {
          fieldErrors.label = 'Item Choice input is required.';
          isValid = false;
        } else if (field.label.length > 255) {
          fieldErrors.label = 'Item Choice must be at most 255 characters.';
          isValid = false;
        } else if (seenLabels.has(field.label.trim())) {
          fieldErrors.label = 'Item Choice must be unique.';
          isValid = false;
        } else {
          seenLabels.add(field.label.trim());
        }
        if (!this.generalError) this.generalError = fieldErrors.label;

        this.$set(this.errors, index, fieldErrors);
      });
      this.formItemData.choices.push({ label: '' });
      return isValid;
    },

    deleteBlankChoice() {
      let lastField = this.formItemData.choices[this.formItemData.choices.length - 1];
      if (lastField.label == '') this.formItemData.choices.splice(this.formItemData.choices.length - 1, 1);
    },

    submitFormItem() {
      this.disabled = true;
      this.submitted = true;
      this.isLoading = true;
      if (this.$v.$invalid || ([this.inputTypeVal.RADIO, this.inputTypeVal.CHECKBOX, this.inputTypeVal.SELECTBOX].includes(Number(this.formItemData.input_type)) ? !this.validateFields() : false)) {
        this.isLoading = false;
        return;
      }
      this.deleteBlankChoice();
      if (this.status == 'Create') {
        this.createFromItem();
      } else if (this.status == 'Update') {
        this.updateFormItem();
      }
    },

    cloneUpdateData(newVal) {
      this.formItemData.input_type = newVal.input_type;
      this.formItemData.label = newVal.label;
      this.formItemData.placeholder = newVal.placeholder;
      this.formItemData.choices = Object.entries(newVal.item_choices).map(([id, label]) => ({
        id: parseInt(id),
        label
      }));
      this.formItemData.choices.push({ label: '' });
    },

    createFromItem() {
      let request = {
        input_type: parseInt(this.formItemData.input_type),
        label: this.formItemData.label,
        placeholder: this.formItemData.placeholder,
        choices: this.formItemData.choices,
        status: this.status
      }
      axios.post('formItem/create', request, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
            .then(res => {
              if (res.data.success == true) {
                this.$emit('callGetItemsList');
                this.$bvModal.hide(this.modalId);
                this.$notification.success("From Item Create Successfully", {
                  timer: 3,
                  position: "bottomCenter"
                });
                this.isLoading = false;
              } else {
                this.disabled = false;
                this.$bvModal.hide(this.modalId);
                this.isLoading = false;
                this.$notification.error("Something Wrong on Create", {
                  timer: 3,
                  position: "bottomCenter",
                });
              }
            }).catch((error) => {
              this.disabled = false;
              if (error.response) {
                if (error.response.status == 422) {
                  this.isLoading = false;
                  this.$notification.error(error.response.data.message, {
                    timer: 3,
                    position: "bottomCenter",
                  });
                } else if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
                }
              }
            });
    },

    transformChoices(choices) {
      const transformedChoices = {};
      let newChoiceIndex = 1;
    
      choices.forEach(choice => {
        if (choice.id) {
          // Use the id as the key
          transformedChoices[choice.id] = choice.label;
        } else {
          // Generate a new key for items without an id
          transformedChoices[`new_choice_${newChoiceIndex}`] = choice.label;
          newChoiceIndex++;
        }
      });
    
      return transformedChoices;
    },

    updateFormItem() {
      let request = {
        id: this.formItemUpdate.id,
        input_type: parseInt(this.formItemData.input_type),
        label: this.formItemData.label,
        placeholder: this.formItemData.placeholder,
        choices: this.transformChoices(this.formItemData.choices),
        status: this.status,
      }
      axios.post('formItem/update', request, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
            .then(res => {
              if (res.data.success == true) {
                this.$emit('callGetItemsList');
                this.$bvModal.hide(this.modalId);
                this.$notification.success("Form Item Update Successfully", {
                  timer: 3,
                  position: "bottomCenter"
                });
                this.isLoading = false;
              } else {
                this.disabled = false;
                this.$bvModal.hide(this.modalId);
                this.isLoading = false;
                this.$notification.error("Something Wrong on Update", {
                  timer: 3,
                  position: "bottomCenter",
                });
              }
            }).catch((error) => {
              this.disabled = false;
              if (error.response) {
                if (error.response.status == 422) {
                  this.isLoading = false;
                  this.$notification.error(error.response.data.message, {
                    timer: 3,
                    position: "bottomCenter",
                  });
                } else if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
                }
              }
            });
    }
  },
};
