import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Select2Multiple from 'v-select2-multiple-component';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import {
    mapGetters
} from "vuex";

export default {
    name: "ApplicantDetail",
    components: {
        MainLayout,
        Select2Multiple,
        Loading
    },

    data() {
        return {
            isLoading: false,
            studentInfo: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            religionArr: null,
            gradeArr: [],
            grade_name: '',
            isAdmin: true,
            classArr: null,
            year_id: 0,
            class_id: 0,
            requiredClass: false,
            requiredYear: false,
            academicYear: 0
        };
    },

    computed: {
        ...mapGetters([
            'getUser'
        ]),
    },

    methods: {
        getReligion() {
            axios.get('getReligion')
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Choose Religion'
                    }];
                    this.religionArr = $defaultSelectOption.concat(res.data);
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getApplicantDetail() {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/applicant/profile/" + this.$route.params.id, request)
                .then(response => {
                    this.studentInfo = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Applied Student Detail API Error", error);
                });
        },

        showList() {
            this.$router.push({
                path: "/applicant/list"
            });
        },

        back() {
            this.$router.go(-1);
        },

        confirm(applicantIdArr, status) {
            var msg = status == 2 ? 'Are you sure you want to approve?' : 'Are you sure you want to reject?';
            if (window.confirm(msg)) {
                this.checkApplicantData(applicantIdArr, status);
            }
        },

        checkApplicantData(applicantIdArr, status) {
            var request = {
                applicant_ids: applicantIdArr,
                result: status,
                campus_id: store.state.user.campus_id,
                created_id: store.state.user.id
            }
            if (applicantIdArr || applicantIdArr.length > 0) {
                axios.post("/applicant/check", request, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                    .then(response => {
                        if (response.data.success == false) {
                            if (window.confirm(response.data.msg)) {
                                this.setApproveReject(applicantIdArr, status, CONFIG.ADDITIONAL_STATUS.OVERWRITE); //newly overwrite
                            } else {
                                this.setApproveReject(applicantIdArr, status, CONFIG.ADDITIONAL_STATUS.KEEP); //keep
                            }
                        } else {
                            this.setApproveReject(applicantIdArr, status, CONFIG.ADDITIONAL_STATUS.CREATE);
                        }
                    })
            } else {
                this.isLoading = false;
                this.passFailDisable = false;
                alert('Please select data.');
            }
        },

        setApproveReject(applicantIdArr, result, status) {
            this.isLoading = true;
            var request = {
                applicant_ids: applicantIdArr,
                result: result,
                campus_id: store.state.user.campus_id,
                created_id: store.state.user.id,
                addtional_status: status
            }

            axios.post("/applicant/setApproveReject", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status == 204) {
                        this.submitted = false;
                        this.getSection(this.grade, {
                            params: {
                                campus_id: store.state.user.campus_id
                            }
                        });
                        this.passFailDisable = false;
                        this.isLoading = false;
                        return;
                    }
                    if (response.data.success == true) {
                        var resMsg = result == 2 ? "Setting Student Approved Successfully" : "Setting Student Rejected Successfully";
                        this.$notification.success(resMsg, {
                            timer: 3,
                            position: "bottomCenter"
                        });
                        this.$router.push({
                            name: "applicantList"
                        });
                    } else {
                        this.isLoading = false;
                        this.passFailDisable = false;
                        alert(response.data.msg);
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    this.passFailDisable = false;
                    console.log("Student Approve/Reject API Error", error.errors);
                });
        },
    },

    mounted() {
        this.isLoading = true;
        this.getApplicantDetail();
        this.getReligion();
    },
};
