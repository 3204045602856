import Vue from 'vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import upLoad from '@/assets/img/upload_photo3.jpg';
import axios from 'axios';
import store from "@/store";
import CONFIG from "@/assets/js/config.js";

export default {
  name: "paymentCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      imageLargeMsg: CONFIG.IMAGE_TOO_LARGE,
      paymentMethodArr: null,
      inputData: {
        payment_method_id: '',
        payment_date: '',
        received_amt: '',
        payment_screenshot: '',
        receiver: '',
        remark: '',
        class_id: '',
        studentName: '',
        student_id: '',
        guardian1_name: '',
        item_id: '',
      },
      inputInfo: [],
      editData: [],
      ssImage: '',
      upLoad,
      classArr: [],
      submitted: false,
      campusId: store.state.user.campus_id,
      allStu: [],
      isLoading: false,
      disabled: false,
      guardian1Arr: [],
      guardian1Name: '',
      itemArr: [],
      itemId: null,
      visible: false,
      itemAmtArr: [],
    };
  },

  validations: {
    inputData: {
      student_id: {
        required
      },
      payment_method_id: {
        required
      },
      payment_date: {
        required
      },
      received_amt: {
        required,
      },
      receiver: {
        required,
        maxLength: maxLength(100)
      },
      class_id: {
        required
      },
      remark: {
        maxLength: maxLength(1000)
      },
      item_id: {
        required
      }
    },
  },

  methods: {
    getItem() {
      axios.get('item/getItemForPayment')
        .then(res => {
          this.itemArr = res.data.data;
          this.itemAmtArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAmt(item_id) {
      var selectedItem = this.itemAmtArr.find(item => item.id == item_id);
      this.inputData.received_amt = selectedItem.p_item_amount;
    },

    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    removeImg() {
      this.ssImage = '';
      document.getElementById('image').value = '';
    },

    getClass() {
      axios.get('class/getGradeAndClass', { params: { campus_id: this.campusId } })
        .then(res => {
          this.isLoading = false;
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];
          this.classArr = $defaultSelectOption.concat(res.data);
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getStudent(id) {
      axios.get('student/getallStudent', { params: { campus_id: this.campusId, class_id: id } })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.allStu = $defaultSelectOption.concat(res.data);
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getGuardian1Name(id) {
      axios.get('student/getGuardian1Name', { params: { campus_id: this.campusId, student_id: id } })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Guardian 1 Name'
          }];
          this.guardian1Arr = $defaultSelectOption.concat(res.data);
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    imgUpload(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return null;
      } else {
        if (e.target.files[0].type.includes("image/")) {
          if (e.target.files[0].type == "image/gif") {
            this.$notification.error("File not supported!", {
              timer: 3,
              position: "bottomCenter",
            });
            return null;
          }
          let reader = new FileReader();
          reader.onload = (e) => {
            if (e.target.result.length > 5242880) {
              this.$notification.error(this.imageLargeMsg, {
                timer: 3,
                position: "bottomCenter",
              });
            } else {
              this.ssImage = e.target.result;
              document.getElementById('image').value = '';
            }
          }
          reader.readAsDataURL(files[0]);
        } else {
          this.$notification.error("File not supported!", {
            timer: 3,
            position: "bottomCenter",
          });
          return null;
        }
      }
    },

    addEditData() {
      this.inputInfo.push(Vue.util.extend({}, this.editData));
      this.editData = [];
    },

    addData() {
      if (this.inputData.item_id && this.inputData.payment_method_id && this.inputData.payment_date && this.inputData.receiver) {
        this.inputInfo.push(Vue.util.extend({}, this.inputData));
      }
    },

    reset() {
      if (this.editData.item_id != null) {
        this.addEditData();
      }
      this.getStudent(0);
      this.getGuardian1Name(0);
      this.inputData = {};
      this.ssImage = '';
    },

    cloneForm() {
      this.editData = [];
      this.submitted = true;
      if (this.$v.$invalid || this.inputData.payment_method_id == '' || this.inputData.payment_method_id == 0 || this.inputData.student_id == 0) {
        return;
      }
      this.cloneData();
    },

    cloneData() {
      if (this.inputInfo.length < 20) {
        var i = this.inputInfo.length;
        this.inputInfo.push(Vue.util.extend({}, this.inputData));
        this.inputInfo[i].payment_screenshot = this.ssImage;
        for (let index = 0; index < this.allStu.length; index++) {
          if (this.allStu[index].id == this.inputData.student_id) {
            this.inputInfo[i].studentName = this.allStu[index].text;
          }
          this.inputInfo[i].guardian1Name = this.guardian1Arr[1] ? this.guardian1Arr[1].text : '';
        }
        this.inputData = {};
        this.getStudent(0);
        this.getGuardian1Name(0);
        this.ssImage = '';
      } else {
        alert('Payment can be created at most 20.');
      }
      this.submitted = false;
      this.disabled = false;
    },

    removeClone(index) {
      Vue.delete(this.inputInfo, index);
    },

    editClone(index) {
      this.addData();
      this.getEditData(this.inputInfo[index]);
      this.inputData = this.inputInfo[index];
      this.ssImage = this.inputInfo[index].payment_screenshot;
      Vue.delete(this.inputInfo, index);
    },

    getEditData(data) {
      this.getStudent(data.class_id);
      this.getGuardian1Name(data.student_id);
      this.editData.item_id = data.item_id;
      this.editData.class_id = data.class_id;
      this.editData.student_id = data.student_id;
      this.editData.guardian1_name = data.guardian1_name;
      this.editData.payment_method_id = data.payment_method_id;
      this.editData.received_amt = data.received_amt;
      this.editData.remark = data.remark;
      this.editData.payment_screenshot = data.payment_screenshot;
      this.editData.receiver = data.receiver;
      this.editData.studentName = data.studentName;
      this.editData.payment_date = data.payment_date;
      this.editData.guardian1Name = data.guardian1Name;
    },

    submitPayment() {
      this.reset();
      for (var i = 0; i < this.inputInfo.length; i++) {
        if (this.inputInfo[i]['payment_method_id'] == 0) {
          this.inputInfo[i]['payment_method_id'] = '';
        }
      }
      this.disabled = true;
      axios.post('payment/create', {
        inputInfo: this.inputInfo,
        length: this.inputInfo.length,
        class_id: this.inputInfo[0]['class_id'],
        loginId: store.state.user.id,
        campus_id: this.campusId
      })
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Payment Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'paymentList'
            })
          }
          if (res.data.status == 201) {
            this.$notification.error("Payment Already Added to this student.", {
              timer: 3,
              position: "bottomCenter"
            });
            this.disabled = false;
            this.isLoading = false;
          } else {
            this.disabled = false;
            this.errors = res.data.errors;
            this.isLoading = false;
          }
          this.disabled = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.disabled = false;
          this.isLoading = false;
          this.errors = error.response.data.errors;
        })
    },

    back() {
      this.$router.go(-1);
    },
  },

  mounted() {
    this.isLoading = true;
    this.getItem();
    this.getClass();
    this.getPaymentMethod();
    if (this.inputInfo.length == 0) {
      this.disabled = true;
    }
  },
};
