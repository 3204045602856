import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import CONFIG from "@/assets/js/config.js";
import axios from 'axios';
import store from '@/store';

export default {
  name: "paymentRequestList",
  components: {
    MainLayout,
    Loading,
    CONFIG,
    flatPickr,
    AdvancedLaravelVuePaginate,
    Select2Multiple,
  },

  data() {
    return {
      paymentData: {},
      campusId: store.state.user.campus_id,
      paymentMethodArr: null,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      searchData: {
        acaYear: this.$store.state.academicYear
      },
      academicYearArr: null,
      payMethod: [],
      itemArr: [],
      classArr: null,
    };
  },

  methods: {
    getClass($yearId) {
        axios.get("class/classList/" + $yearId, {
            params: {
                campus_id: this.$store.state.user.campus_id,
            }
        })
            .then(res => {
                let $defaultSelectOption = [{
                    'id': 0,
                    'text': 'Select Class'
                }];
                this.classArr = $defaultSelectOption.concat(res.data);
                this.isLoading = false;
            }).catch((error) => {
                if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                }
                this.isLoading = false;
            });
    },
    getItem() {
      axios.get('item/getItemForPayment')
        .then(res => {
          this.itemArr = res.data.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAcademicYear() {
      axios.get('getAcademicYear', {
          params: {
              campus_id: store.state.user.campus_id
          }
        })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getPaymentMethod() {
      axios.get('getPaymentMethod')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Payment Method'
          }];
          this.paymentMethodArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getPayment(page = 1) {
      axios.get('payment_request/list?page=' + page, { params: { campus_id: this.campusId } })
        .then(res => {
          if (res.data) {
            this.paymentData = res.data;
          }
          this.isLoading = false;
          for (let y = 0; y < this.paymentData.data.length; y++) {
            let method = this.paymentData.data[y].payment_methods;
            let methodArray = method.split(', ');
            for (let index = 0; index < this.paymentMethodArr.length; index++) {
              for (let i = 0; i < methodArray.length; i++) {
                if (this.paymentMethodArr[index].text == methodArray[i]) {
                  methodArray[i] = ' ' + this.paymentMethodArr[index].text;
                  if(!this.payMethod.includes(methodArray[i])){
                    this.payMethod.push(methodArray[i]);
                  }
                }
              }
            }
            this.paymentData.data[y].payment_methods = String(this.payMethod);
            this.payMethod = [];
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      } else {
        console.log("no");
      }
    },

    delete(id) {
      this.isLoading = true;
      axios.post(`payment/delete/${id}` + '/' + store.state.user.campus_id)
      .then(res => {
        this.isLoading = false;
        if (res.data)
          this.getPayment();
      })
    },

    searching(page = 1) {
      this.isLoading = true;
      if (this.searchData.p_method == 0) {
        this.searchData.p_method = undefined;
      }
      axios.get('payment_request/search?page=' + page, {
        params: {
          stuName : this.searchData.stuName,
          guardian1Name: this.searchData.guardian1Name,
          item_id: this.searchData.item_id,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt,
          p_method: this.searchData.p_method,
          startDate: this.searchData.startDate,
          endDate: this.searchData.endDate,
          acaYear: this.searchData.acaYear,
          classId: this.searchData.class_id,
          invoiceNumber: this.searchData.invoiceNumber,
          campus_id: this.campusId
        }
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data) {
            this.paymentData = res.data;
          }
          for (let y = 0; y < this.paymentData.data.length; y++) {
            let method = this.paymentData.data[y].payment_methods;
            let methodArray = method.split(', ');
            for (let index = 0; index < this.paymentMethodArr.length; index++) {
              for (let i = 0; i < methodArray.length; i++) {
                if (this.paymentMethodArr[index].text == methodArray[i]) {
                  methodArray[i] = ' ' + this.paymentMethodArr[index].text;
                  if(!this.payMethod.includes(methodArray[i])){
                    this.payMethod.push(methodArray[i]);
                  }
                }
              }
            }
            this.paymentData.data[y].payment_methods = String(this.payMethod);
            this.payMethod = [];
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData = [];
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/payment/excelDownload', {
        params: {
          stuName: this.searchData.stuName,
          guardian1Name: this.searchData.guardian1Name,
          item_id: this.searchData.item_id,
          fromAmt: this.searchData.fromAmt,
          toAmt: this.searchData.toAmt,
          p_method: this.searchData.p_method,
          startDate: this.searchData.startDate,
          endDate: this.searchData.endDate,
          acaYear: this.searchData.acaYear,
          classId: this.searchData.class_id,
          invoiceNumber: this.searchData.invoiceNumber,
          campus_id: this.campusId
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          let date = new Date();
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `payment_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate() }.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.isLoading = true;
    this.getPaymentMethod();
    this.getItem();
    this.getAcademicYear();
    this.getPayment();
  },
};
