import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";
import store from '../../store';
import {
  required, maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "examCreate",
  components: {
    MainLayout,
    Select2Multiple,
    flatPickr,
    Loading
  },

  data() {
    return {
      isLoading: false,
      classData: {},
      selectedClass: '',
      classArr: [],
      academic_year: '',
      academicYear: '',
      class_id: '',
      exam_name: '',
      exam_start_date: '',
      exam_end_date: '',
      academicYearArr: null,
      gradingArr: null,
      submitted: false,
      id: 0,
      isError: false,
    };
  },

  validations: {
    academic_year: {
      required
    },
    exam_start_date: {
      required
    },
    exam_end_date: {
      required
    },
    exam_name: {
      required,
      maxLength: maxLength(100)
    },
  },

  methods: {
    getCurrentPeriodList() {
      axios.get('/period/getCurrentPeriodList', {
        params: {
          campus_id: store.state.user.campus_id,
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Academic Year'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.submitted = false;
      this.$set(this, fieldName, val);
      if (fieldName == "academic_year") {
        this.isLoading = true;
        this.classArr = [];
        this.selectedClass = this.class_id = '';
        this.exam_name = '';
        this.getClass(val);
      }
    },

    getClass(academic_year) {
      axios.get("class/classList/" + academic_year, {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          if (res.data.length > 0) {
            let $defaultSelectOption = [{
              'id': 0,
              'text': 'All'
            }];
            this.classArr = $defaultSelectOption.concat(res.data);
            this.isLoading = false;
          } else {
            this.classArr = [];
            this.isLoading = false;
          }
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmRegister() {
      this.id = 0;
      this.submitted = true;
      this.isLoading = true;
      if (this.exam_start_date && this.exam_end_date && new Date(this.exam_end_date) < new Date(this.exam_start_date)) {
        this.isError = true;
      } else {
        this.isError = false;
      }
      if (this.$v.$invalid || this.class_id == '' || this.class_id.length == 0 || this.academic_year == '' || this.academic_year == 0 || this.isError) {
        this.isLoading = false;
        return;
      }
      this.register();
    },

    register() {
      if (this.class_id != null) {
        if (this.class_id.includes('0')) {
          for (let i = 1; i < (this.classArr.length); i++) {
            this.class_id[i] = this.classArr[i].id;
          }
        }
      }
      var request = {
        academic_year: this.academic_year,
        class_id: this.class_id,
        exam_name: this.exam_name,
        exam_start_date: this.exam_start_date,
        exam_end_date: this.exam_end_date,
        campus_id: store.state.user.campus_id,
        created_id: store.state.user.id
      }

      axios.post("/exam/create", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.status == 200) {
            this.isLoading = false;
            this.$notification.success("Exam Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "examList"
            });
          }
          else if (response.data.success == false) {
            this.isLoading = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
          else if (response.data.status == 201) {
            this.isLoading = false;
            this.$notification.error("Exam data already exist.", {
              timer: 3,
              position: "bottomCenter",
            });
          } else {
            this.isLoading = false;
            this.$notification.error("something went wrong", {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Exam Create API Error", error.errors);
        });
    },

    showList() {
      this.$router.push({
        path: "/exam/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getCurrentPeriodList();
  },
};
