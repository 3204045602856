import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
  name: "CheckoutList",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    AdvancedLaravelVuePaginate,
    Loading,
  },

  data() {
    return {
      checkoutData: {},
      searchData: {
        name: '',
        book_name: '',
        category_id: '',
        type: '',
        start_date: '',
        to_date: '',
        return_date: '',
        is_return: null
      },
      returnStatusArr: [
        { id: 0, text: 'Select Status' },
        { id: 1, text: 'Returned' },
        { id: 2, text: 'Not Returned' },
      ],
      categoryArr: null,
      typeArr: [
        { id: 0, text: 'Select Type' },
        { id: 1, text: 'Physical Book' },
        { id: 2, text: 'Ebook' },
      ],
      selectedStatus: 0,
      selectedCategory: 0,
      selectedType: 0,
      noDataMsg: CONFIG.NO_DATA_FOUND,
      isLoading: false,
      search_status: false,
      searchBtnStatus: false,
      campusId: '',
      bookTypeOptions: CONFIG.BOOK_TYPE,
    };
  },

  methods: {
    changeEvent(val, fieldName) {
      fieldName == 'status' && val == 0 ? this.$set(this, fieldName, '') : this.$set(this, fieldName, val);
    },

    getCheckoutList(page = 1) {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/checkout/list?page=" + page, request)
        .then(response => {
          this.search_status = false;
          this.checkoutData = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Checkout List API Error", error);
        });
    },

    getCategoryList() {
      this.book_id = 0;
      let $defaultSelectOption = [{
        'id': 0,
        'text': 'Select Category'
      }];
      axios.get('/category/getList')
        .then(res => {
          if (res.data.data.length > 0) {
            this.categoryArr = $defaultSelectOption.concat(res.data.data);
          } else {
            this.categoryArr = $defaultSelectOption;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    reset() {
      this.searchData.name = ''
      this.searchData.book_name = ''
      this.searchData.category_id = ''
      this.searchData.type = null
      this.searchData.start_date = ''
      this.searchData.to_date = ''
      this.searchData.return_date = ''
      this.searchData.is_return = null
    },
    
    searchCheckout(page = 1) {
      this.isLoading = true;
      var request = {
        params: {
          name: this.searchData.name,
          book_name: this.searchData.book_name,
          category_id: this.searchData.category_id,
          type: this.searchData.type,
          start_date: this.searchData.start_date,
          to_date: this.searchData.to_date,
          return_date: this.searchData.return_date,
          is_return: this.searchData.is_return,
          campus_id: this.$store.state.user.campus_id
        }
      }

      axios.get("/checkout/search?page=" + page, request)
        .then(response => {
          this.search_status = true;
          this.checkoutData = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Checkout Search API Error", error);
        });
    },

    confirmDelete(id) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.delete(id);
      }
    },

    delete(id) {
      axios.delete("/checkout/delete/" + id)
        .then(res => {
          if (res.data.success == true) {
            this.isLoading = true;
            this.getCheckoutList();
            this.$notification.success("Checkout Data Deleted Successfully.", {
              timer: 3,
              position: "bottomCenter"
            });
          }
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },
    
    showCreate() {
      this.$router.push({
        path: "/checkout/create"
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "checkoutEdit",
        params: {
          id: id
        }
      });
    },

    showDetail(classId) {
      this.$router.push({
        name: "checkoutDetail",
        params: {
          id: classId
        }
      });
    },

    excelDownload() {
      this.isLoading = true;
      axios.get('/checkout/excelDownload', {
        params: {
          name: this.searchData.name,
          book_name: this.searchData.book_name,
          category_id: this.searchData.category_id,
          type: this.searchData.type,
          start_date: this.searchData.start_date,
          to_date: this.searchData.to_date,
          return_date: this.searchData.return_date,
          is_return: this.searchData.is_return,
          campus_id: this.$store.state.user.campus_id
        },
        responseType: 'arraybuffer'
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          let date = new Date();
          fileLink.setAttribute('download', `checkout_list_${date.getFullYear()}${(date.getMonth() + 1) < 9 ? 0 : ''}${(date.getMonth() + 1)}${date.getDate() < 9 ? 0 : ''}${date.getDate()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.isLoading = false;
        }).catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    }
  },

  mounted() {
    this.campusId = this.$store.state.user.campus_id;
    this.isLoading = true;
    this.getCheckoutList();
    this.getCategoryList();
  },
};
