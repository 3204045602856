import { VueEditor } from 'vue2-quill-editor';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css'; // import styles
import 'quill/dist/quill.core.css';
export default {
    name: "InitialScreenPreview",
    components: {
        VueEditor,
    },
  
    data() {
        return {
            htmlContent: this.$store.state.initialScreen,
        };
    },
};
