import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
  name: "appFormDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      appFormData: {},
      campusGradeArr: [],
      appTypeText: CONFIG.APPLICATION.TYPE_TXT,
      appFormType: CONFIG.APPLICATION.TYPE,
      parentRequired: CONFIG.PARENT_REQUIRED,
      studentRequired: CONFIG.STUDENT_REQUIRED,
      itemTypes: CONFIG.ITEM_TYPES.TYPE,
      inputTypes: CONFIG.INPUT_TYPES,
      appUrl: CONFIG.APPLICATION_URL.replace(/\/$/, '') + '/',
      parentFormItemsArr: [],
      studentFormItemsArr: [],
      additionalFormItemsArr: [],
    };
  },

  methods: {
    async getAppFormDetail() {
      await axios.get('appForm/detail/' + this.$route.params.id)
                .then(res => {
                  this.appFormData = res.data;
                }).catch(error => {
                  if (error.response && error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                  }
                  this.isLoading = false;
                })
    },

    getCampusGradeName() {
      axios.get('getAcademicYearCampusGradeName', {
          params: {
              campus_id: store.state.user.campus_id
          }
      }).then(res => {
          let $defaultSelectOption = [{
              "id": 0,
              "text": "Select Grade Name"
          }];
          this.campusGradeArr = $defaultSelectOption.concat(res.data);
      }).catch((error) => {
          if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
          }
          this.isLoading = false;
      });
    },

    async getItemsList () {
      if (this.appFormData.type == null) {
        this.isLoading = false;
        return;
      }
      let formType = this.appFormData.type;
      if (formType == this.appFormType.PARENT) {
        await axios.get('item/list/' + this.itemTypes.PARENT)
                  .then(res => {
                    let parentItems = res.data.filter( item => 
                      this.isInItemArr(item.id) || this.parentRequired.includes(item.key)
                    );
                    this.parentFormItemsArr = parentItems;
                    this.isLoading = false;
                  }).catch((error) => {
                    if (error.response.status == 401) {
                      this.$store.commit('logout');
                      window.location.replace('login');
                    }
                    this.isLoading = false;
                  });
      }
      await axios.get('item/list/' + this.itemTypes.STUDENT)
                .then(res => {
                  let studentItems = res.data.filter( item => 
                    this.isInItemArr(item.id) || this.studentRequired.includes(item.key)
                  );
                  this.studentFormItemsArr = studentItems;
                  this.isLoading = false;
                }).catch((error) => {
                  if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                  }
                  this.isLoading = false;
                });
      await axios.get('item/list/' + this.itemTypes.ADDITIONAL)
                .then(res => {
                  let additeonalItems = res.data.filter( item => 
                    this.isInItemArr(item.id)
                  );
                  this.additionalFormItemsArr = additeonalItems;
                  this.isLoading = false;
                }).catch((error) => {
                  if (error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                  }
                  this.isLoading = false;
                });
    },

    isInItemArr($formItemId) {
      return Object.keys(this.appFormData.items_arr).includes($formItemId.toString());
    },

    isRequired($formItemId) {
      return this.appFormData.items_arr[$formItemId];
    },

    concatItemChoices(choices){
      if (choices == null && choices.length == 0) {
        return '';
      }
      var itemChoices = '';
      for (const choice of choices) {
        itemChoices = itemChoices + choice.label + ', ';
      }
      itemChoices = itemChoices.replace(/,\s*$/, '');
      return itemChoices;
    }
  },

  async mounted() {
    this.isLoading = true;
    await this.getCampusGradeName();
    await this.getAppFormDetail();
    await this.getItemsList();
  },
};
