import MainLayout from "@/components/layout/admin/MainLayout.vue";
import FormItemModal from "@/components/application_form/formItemModal.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import Select2Multiple from "v-select2-multiple-component";
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import {
  mapState, mapMutations 
} from "vuex";

export default {
  name: "AppFormItems",
  components: {
    MainLayout,
    FormItemModal,
    Loading,
    Select2Multiple,
  },

  data() {
    return {
      parentFormItemsArr: [],
      studentFormItemsArr: [],
      additionalFormItemsArr: [],
      parentRequired: CONFIG.PARENT_REQUIRED,
      studentRequired: CONFIG.STUDENT_REQUIRED,
      studentAdditionalRequired: CONFIG.STUDENT_ADDITIONAL_REQUIRED,
      appFormType: CONFIG.APPLICATION.TYPE,
      inputTypes: CONFIG.INPUT_TYPES,
      itemTypes: CONFIG.ITEM_TYPES.TYPE,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      inputTypeArr: [],
      isLoading: false,
      disabled: false,
      itemSelected: {},
      formItemData: {},
    };
  },

  computed: {
    ...mapState({
      xappFormData: state => state.xappFormData
    })
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == 'appFormCreate') {
      this.setCameFromItemsPage(true);
    } else {
      this.resetAppFormData();
    }
    next();
  },

  methods: {
    ...mapMutations(['setCameFromItemsPage', 'resetAppFormData']),

    getItemsList () {
      let formType = this.xappFormData.type;
      if (formType == this.appFormType.PARENT) {
        axios.get('item/list/' + this.itemTypes.PARENT)
              .then(res => {
                this.parentFormItemsArr = res.data;
              }).catch((error) => {
                if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
                }
                this.isLoading = false;
              });
      }
      axios.get('item/list/' + this.itemTypes.STUDENT)
            .then(res => {
              this.studentFormItemsArr = res.data;
              if (formType == this.appFormType.STUDENT) {
                this.studentRequired = this.studentRequired.concat(this.studentAdditionalRequired);
              }
            }).catch((error) => {
              if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.isLoading = false;
            });
      axios.get('item/list/' + this.itemTypes.ADDITIONAL)
            .then(res => {
              this.additionalFormItemsArr = res.data;
              this.isLoading = false;
            }).catch((error) => {
              if (error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
              this.isLoading = false;
            });
    },

    updateSelected(e, itemId) {
      if (!e.target.checked) {
        this.$delete(this.itemSelected, itemId);
      }else {
        this.$set(this.itemSelected, itemId, false);
      }
    },

    isItemSelected(id) {
      return Object.keys(this.itemSelected).includes(id.toString());
    },

    isRequired($formItemId) {
      return this.itemSelected[$formItemId];
    },

    updateRequired(e, itemId) {
      if (!e.target.checked) {
        this.$set(this.itemSelected, itemId, false);
      }else {
        this.$set(this.itemSelected, itemId, true);
      }
    },

    submit() {
      this.disabled = true;
      let request = {
        'campus_id': this.$store.state.user.campus_id,
        'title': this.xappFormData.title,
        'url': this.xappFormData.url,
        'type' : parseInt(this.xappFormData.type),
        'campus_grade_id' : parseInt(this.xappFormData.campus_grade_id),
        'start_date' : this.xappFormData.start_date + ":00",
        'end_date' : this.xappFormData.end_date + ":00",
        'items_arr' : this.itemSelected,
        'status' : 'Create',
        'initial_screen': this.xappFormData.initial_screen,
      };
      axios.post('appForm/create', request)
            .then(res => {
              if (res.data.success == true) {
                this.resetAppFormData();
                this.$notification.success("Application Form Create Successfully", {
                  timer: 3,
                  position: "bottomCenter"
                });
                this.$router.push({
                  name: 'appFormList'
                })
              } else {
                this.disabled = false;
                this.$notification.error("Something Wrong on Create", {
                  timer: 3,
                  position: "bottomCenter",
                });
              }
            }).catch((error) => {
              this.disabled = false;
              this.isLoading = false;
              if (error.response) {
                if (error.response.status == 401) {
                  this.$store.commit('logout');
                  window.location.replace('login');
                }
              }
            });
    },

    showModel() {
      this.$bvModal.show('formItemCreate');
    },

    setInputType() {
      let defaultArr = [
        {
          id: 0,
          text: "Select Input Type",
        }
      ];
      let typeArray = Object.keys(this.inputTypes).map(key => ({
        id: parseInt(key),
        text: this.inputTypes[key]
      }));
      this.inputTypeArr = defaultArr.concat(typeArray);
      this.inputTypeArr = this.inputTypeArr.filter(item => item.id != CONFIG.INPUT_TYPES_VAL.FILE)
    },

    confirmDelete(formItemId) {
      if (window.confirm("The selected form item will be deleted in all application form. Are you sure you want to delete?")) {
        this.deleteFormItem(formItemId);
      }
    },

    deleteFormItem(formItemId) {
      this.isLoading = true;
      axios.delete("formItem/delete/" + formItemId)
          .then(response => {
            if (response.data.success == true) {
                this.getItemsList();
              } else {
                this.isLoading = false;
                this.$notification.error("Something Wrong on Form Item Delete.", {
                  timer: 3,
                  position: "bottomCenter",
                });
              }
            })
            .catch(error => {
              if (error.response && error.response.status == 401) {
                this.$store.commit('logout');
                window.location.replace('login');
              }
            });
    },

    async updateFormItem(formItemId) {
      await this.getFormItemDetail(formItemId);
      this.$bvModal.show('formItemUpdate');
    },

    async getFormItemDetail(formItemId) {
      await axios.get('formItem/detail/' + formItemId)
                .then(res => {
                  this.formItemData = { ...res.data };
                }).catch(error => {
                  if (error.response && error.response.status == 401) {
                    this.$store.commit('logout');
                    window.location.replace('login');
                  }
                })
    },

    concatItemChoices(choices){
      if (choices == null && choices.length == 0) {
        return '';
      }
      var itemChoices = '';
      for (const choice of choices) {
        itemChoices = itemChoices + choice.label + ', ';
      }
      itemChoices = itemChoices.replace(/,\s*$/, '');
      return itemChoices;
    },
  },
  
  mounted() {
    this.isLoading = true;
    if (this.xappFormData.title == '') {
      this.$router.push({ name: 'appFormCreate'});
    }
    this.getItemsList();
    this.setInputType();
  },
};
