import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from "axios";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";

export default {
  name: "examDetail",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      isLoading: false,
      examInfo: {},
      academicYearArr: null,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      examData: {},
      academicYear: '',
    };
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    async getExamDetail() {
      try {
        this.isLoading = true;

        const response = await axios.get("/exam/detail", {
          params: {
            campus_id: store.state.user.campus_id,
            exam_id: this.$route.params.exam_id
          }
        });

        if (response.data[0] != undefined) {
          this.examInfo = response.data[0];
          this.examData = response.data;
        }
      } catch (error) {
        if (error.response && error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        console.error("Exam Detail API Error", error);
      } finally {
        this.isLoading = false;
      }
    },

    showList() {
      this.$router.push({
        path: "/exam/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getAcademicYear();
    this.getExamDetail();
  },
};
