import MainLayout from "@/components/layout/admin/MainLayout.vue";
import flatPickr from 'vue-flatpickr-component';
import Select2Multiple from "v-select2-multiple-component";
import Loading from "vue-loading-overlay";
import 'flatpickr/dist/flatpickr.css';
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import store from '@/store'

export default {
  name: "checkoutCreate",
  components: {
    MainLayout,
    flatPickr,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      checkoutData: {
        library_member_id: null,
        category_id: null,
        book_id: null,
        start_date: '',
        end_date: '',
        return_date: '',
        is_return: '',
        login_id: store.state.user.id,
      },
      isError: false,
      submitted: false,
      campusId: store.state.user.campus_master.campus_code,
      disabled: false,
      memberArr: null,
      categoryArr: null,
      bookArr: null,
      isLoading: false,
      noMemberMsg: '',
      noCategoryMsg: '',
      noBookMsg: '',
    };
  },

  validations: {
    checkoutData: {
      library_member_id: {
        required,
      },
      category_id: {
        required
      },
      book_id: {
        required,
      },
      start_date: {
        required
      },
      end_date: {
        required
      },
      is_return: {
        required
      },
    },
  },

  methods: {
    getMember() {
      let $defaultSelectOption = [{
        'id': 0,
        'text': 'Select Member'
      }];
      axios.get("/member/getList", {
        params: {
          campus_id: this.$store.state.user.campus_id,
        }
      })
        .then(res => {
          if (res.data.data.length > 0) {
            this.memberArr = $defaultSelectOption.concat(res.data.data);
          } else {
            this.memberArr = $defaultSelectOption;
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getCategory() {
      this.book_id = 0;
      let $defaultSelectOption = [{
        'id': 0,
        'text': 'Select Category'
      }];
      axios.get('/category/getList')
        .then(res => {
          if (res.data.data.length > 0) {
            this.categoryArr = $defaultSelectOption.concat(res.data.data);
          } else {
            this.categoryArr = $defaultSelectOption;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getBookListByCategoryId(categoryId) {
      var request = {
        params: {
          category_id: categoryId,
          campus_id: this.$store.state.user.campus_id
        }
      }
      axios.get("/book/getList", request)
        .then((res) => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select Book'
          }];
          if (res.data.data.length > 0) {
            this.bookArr = $defaultSelectOption.concat(res.data.data);
            this.noCategoryMsg = '';
            this.noBookMsg = '';
          } else {
            this.bookArr = $defaultSelectOption;
            this.noCategoryMsg = 'There is no category.'
            this.noBookMsg = 'No Book for this campus.'
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeCategoryEvent(categoryId) {
      this.checkoutData.category_id = 0;
      this.checkoutData.book_id = 0;
      this.getBookListByCategoryId(categoryId);
    },

    create() {
      this.submitted = true;
      if (this.checkoutData.start_date && this.checkoutData.end_date && new Date(this.checkoutData.end_date) < new Date(this.checkoutData.start_date)) {
        this.isError = true;
      } else {
        this.isError = false;
      }

      if (this.$v.$invalid || this.isError) {
        return;
      }
      this.disabled = true;
      axios.post('checkout/create', this.checkoutData)
        .then(res => {
          if (res.data.success == true) {
            this.$notification.success("Checkout Form Added Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: 'checkoutList'
            })
          } else {
            this.disabled = false;
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
        }).catch((error) => {
          this.disabled = false;
          this.isLoading = false;
          if (error.response) {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            if (error.response.status == 422) {
              this.$notification.error(error.response.data.msg, {
                timer: 3,
                position: "bottomCenter",
              });
            }
          }
        });
    },
  },
  mounted() {
    this.getMember();
    this.getCategory();
  }
};
