import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { required } from "vuelidate/lib/validators";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "libraryMemberEdit",
    components: {
        MainLayout,
        flatPickr,
        Loading
    },

    data() {
        return {
            isLoading: false,
            submitted: false,
            disabled: false,
            libraryMemberData: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            lmstatus: CONFIG.LIBRARY_MEMBER_STATUS,
        };
    },

    validations: {
        libraryMemberData: {
            date: {
                required
            },
            status: {
                required
            }
        }
    },

    methods: {
        getLibraryMemberData() {
            const memberId = parseInt(this.$route.params.id, 10);
            // Check if the ID is a valid integer
            if (isNaN(memberId) || memberId <= 0) {
                console.log("Invalid Library Member ID");
                this.isLoading = false;
                this.$notification.error("Invalid Library Member ID provided.", {
                    timer: 3,
                    position: "bottomCenter",
                });
                return;
            }
        
            // Proceed with the API call if the ID is valid
            axios.get(`libraryMember/detail/${memberId}`)
                .then(response => {
                    this.libraryMemberData = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Library Member Student Detail API Error", error);
                });
        },        

        update() {
            this.disabled = true;
            var request = {
                id: this.$route.params.id,
                student_ids: this.libraryMemberData.student_id,
                status: this.libraryMemberData.status,
                campus_id: store.state.user.campus_id,
                card_no: this.libraryMemberData.card_no,
            };

            axios.put("/libraryMember/update", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.data.success == true) {
                        this.$notification.success("Library Member Updated Successfully", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                        this.showList();
                    } else {
                        this.disabled = false;
                        this.$notification.error(response.data.message, {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.disabled = false;
                    this.isLoading = false;
                    console.error("Library Member Update API Error:", error);
                    if (error.response) {
                        console.error('Library Member Update API Error:', error.response.data);
                    } else {
                        console.error('Library Member Update API Error:', error.message);
                    }
                }
                );
        },

        statusData() {
            return {
                libraryMemberData: {
                    status: '',
                },
                lmstatus: CONFIG.LIBRARY_MEMBER_STATUS,
            };
        },

        showList() {
            this.$router.push({
                path: "/libraryMember/list"
            });
        },

    },
    mounted() {
        this.statusData();
        this.isLoading = true;
        this.getLibraryMemberData();
    },
};
