import MainLayout from "@/components/layout/admin/MainLayout.vue";
import CategoryItemModal from "@/components/library/categoryItemModal.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import Select2Multiple from "v-select2-multiple-component";
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "CategoryItems",
    components: {
        MainLayout,
        CategoryItemModal,
        AdvancedLaravelVuePaginate,
        Loading,
        Select2Multiple,
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_EXIST,
            inputTypeArr: [],
            isLoading: false,
            disabled: false,
            categoryItemData: {},
            categoryItems: {
                data: [],
                meta: {}
            },
        };
    },

    methods: {
        getItemsList(page = 1) {
            this.isLoading = true;
            axios.get('category/list?page=' + page)
                .then(res => {
                    this.categoryItems = res.data;
                    this.isLoading = false;

                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        showModel() {
            this.$bvModal.show('categoryItemCreate');
        },

        confirmDelete(categoryItemId) {
            if (window.confirm("The selected Category item will be deleted in Categories. Are you sure you want to delete?")) {
                this.deleteFormItem(categoryItemId);
            }
        },

        deleteFormItem(categoryItemId) {
            this.isLoading = true;
            axios.delete("category/delete/" + categoryItemId)
                .then(response => {
                    if (response.data.success == true) {
                        this.getItemsList();
                        this.isLoading = false;
                    } else {
                        this.isLoading = false;
                        this.$notification.error("Something Wrong on Category Item Delete.", {
                            timer: 3,
                            position: "bottomCenter",
                        });
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                });
        },

        async updateFormItem(itemId) {
            await this.getFormItemDetail(itemId);
            this.showUpdateModal();
        },

        async getFormItemDetail(categoryItemId) {
            await axios.get('category/detail/' + categoryItemId)
                .then(res => {
                    this.categoryItemData = { ...res.data };
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                });
        },

        showUpdateModal() {
            this.$bvModal.show('categoryItemUpdate');
        },

        focusIn() {
            if (document.activeElement === this.$refs.someElement) return;
        },

        formatDescription(description) {
            return description ? description.replace(/\n/g, '<br>') : '';
        },

        hasBook(){
            this.isLoading = false;
            axios.delete("hasCategoryInBook/")
                .then(response => {
                    if (response.data.success == true) {
                        this.isLoading = false;
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                });
        }

    },

    mounted() {
        this.isLoading = true;
        this.getItemsList();
    },
};
