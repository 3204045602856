import Vuex from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      token: null,
    },
    setting: {
      teacherChatApproval: false,
    },
    xappFormData: {
      id: null,
      title: '',
      url: '',
      type: 0,
      start_date: '',
      end_date: '',
      items_arr: {},
      initial_screen: '',
    },
    cameFromItemsPage: false,
  },

  mutations: {
    storeUser(state, userData) {
      state.user = userData;
    },
    storeToken(state, userToken) {
      state.user.token = userToken;
    },
    logout(state) {
      state.user = [];
    },
    setStudentNo(state, studentNo) {
        state.studentNo = studentNo;
    },
    setChatApproval(state, status) {
      state.setting.teacherChatApproval = status;
    },
    setAppFormData(state, formData) {
      state.xappFormData = { ...state.xappFormData, ...formData};
    },
    setCameFromItemsPage(state, status) {
      state.cameFromItemsPage = status;
    },
    resetAppFormData (state) {
      state.xappFormData = {
        title: '',
        url: '',
        type: 0,
        start_date: '',
        end_date: '',
      }
    },
    setInitialScreen(state, initialScreen) {
      state.initialScreen = initialScreen;
    }
  },

  actions: {
    login({ commit }, credentials) {
      return axios.post("login", credentials).then(({ data }) => {
        commit("storeUser", data.account);
        commit("storeToken", data.token);
      });
    },
    loginByBtn({ commit }, credentials) {
      return axios.post('admin/login', credentials)
        .then(({ data }) => {
          console.log({data});
          commit("storeUser", data.data);
          commit("storeToken", data.token);
        });
    },
    getSetting({ commit, state }) {
      if (state.user.campus_id) {
        return axios
              .get("/setting/" + state.user.campus_id)
              .then(({ data }) => {
                commit("setChatApproval", data.teacher_chat_approval == 1 ? true : false);
              })
              .catch(error => {
                if (error.response.status == 401) {
                  commit('logout');
                  window.location.replace('login');
                }
              });
      }
    },
  },

  plugins: [createPersistedState()],

})
