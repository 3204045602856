import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Select2Multiple from 'v-select2-multiple-component';
import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';

export default {
    name: "libraryMemberList",
    components: {
        MainLayout,
        flatPickr,
        Select2Multiple,
        AdvancedLaravelVuePaginate,
        Loading,
    },

    data() {
        return {
            name: '',
            campus_name: '',
            class_id: '',
            status: '',
            cardNo: '',
            attendanceStatusArr: [
                { id: 0, text: 'Select Status' },
                { id: 1, text: 'Active' },
                { id: 2, text: 'Inactive' },
                { id: 3, text: 'Block' }
            ],
            academicYearArr: null,
            selectedStatus: 0,
            libraryMemberList: {
                campus_id: '',
            },
            classArr: [],
            selectedYear: 0,
            selectedClass: 0,
            noDataMsg: CONFIG.NO_DATA_FOUND,
            isLoading: false,
            search_status: false,
            searchBtnStatus: false,
            campusId: '',
            lmstatus: CONFIG.LIBRARY_MEMBER_STATUS,
        };
    },

    methods: {
        campusNameAction() {
            if (this.adminNo == '0001') {
                axios.get('campus/campusName')
                    .then(res => {
                        this.campusName = res.data;
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            this.$store.commit('logout');
                            window.location.replace('login');
                        }
                        this.isLoading = false;
                    });
            } else {
                this.campusName = this.$store.state.user.campus_master.campus_name;
                if (!this.libraryMemberList) {
                    this.libraryMemberList = {};
                }
                this.libraryMemberList.campus_id = this.$store.state.user.campus_master.id;
            }
        },

        changeEvent(val, fieldName) {
            fieldName == 'status' && val == 0 ? this.$set(this, fieldName, '') : this.$set(this, fieldName, val);
        },

        getlibraryMemberList(page = 1) {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/libraryMember/list?page=" + page, request)
                .then(response => {
                    this.search_status = false;
                    this.libraryMemberList = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Attendance List API Error", error);
                });
        },

        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: store.state.user.campus_id
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        search(page = 1) {
            this.searchBtnStatus = true;
            this.isLoading = true;
            var request = {
                params: {
                    name: this.name,
                    class_id: this.class_id,
                    campus_name: this.campus_name,
                    status: this.status,
                    cardNo: this.cardNo,
                    campus_id: store.state.user.campus_id
                }
            }
            axios.get("/libraryMember/search/result?page=" + page, request)
                .then(response => {
                    this.search_status = true;
                    this.libraryMemberList = response.data;
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Student Attendance Search API Error", error);
                });
        },

        reset() {
            this.name = '';
            this.campus_name = '';
            this.class_id = '';
            this.cardNo = '';
            this.status = '';
            this.selectedStatus = 0;
            this.selectedClass = this.selectedYear = 0;
        },

        confirmDelete(id) {
            if (window.confirm("Are you sure you want to delete?")) {
                this.deleteClass(id);
            } else {
                console.log("no");
            }
        },

        deleteClass(id) {
            var request = {
                params: {
                    campus_id: store.state.user.campus_id
                }
            }
            this.isLoading = true;
            axios.delete("/libraryMember/delete/" + id, request)
                .then(response => {
                    if (response.data.success == true) {
                        this.isLoading = true;
                        this.getlibraryMemberList();
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Attendance Delete Error->", error.response.data);
                });
        },

        showCreate() {
            this.$router.push({
                path: "/libraryMember/create"
            });
        },

        showEdit(attendanceId) {
            this.$router.push({
                name: "libraryMemberEdit",
                params: {
                    id: attendanceId
                }
            });
        },

        showDetail(attendanceId) {
            this.$router.push({
                name: "libraryMemberDetail",
                params: {
                    id: attendanceId
                }
            });
        },
    },

    mounted() {
        this.campusId = this.$store.state.user.campus_id;
        this.isLoading = true;
        this.campusNameAction();
        this.getlibraryMemberList();
    },
};
