import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import axios from "axios";
import store from '../../store';
import CONFIG from "@/assets/js/config.js";
import {
  required, maxLength
} from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "examEdit",
  components: {
    MainLayout,
    Select2Multiple,
    flatPickr,
    Loading
  },

  data() {
    return {
      isLoading: false,
      noDataMsg: CONFIG.NO_DATA_EXIST,
      classData: {},
      selectedClass: 0,
      selectedYear: 0,
      classArr: [],
      academic_year: '',
      academicYear: '',
      exam_name: '',
      exam_start_date: '',
      exam_end_date: '',
      class_id: '',
      gradingSelected: 0,
      grading_id: 0,
      academicYearArr: null,
      submitted: false,
      gradingInfo: {},
      gradeData: {},
      isError: false,
    };
  },

  validations: {
    class_id: {
      required
    },
    academic_year: {
      required
    },
    exam_name: {
      required,
      maxLength: maxLength(100)
    },
    exam_start_date: {
      required
    },
    exam_end_date: {
      required
    },
  },

  methods: {
    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
          campus_id: store.state.user.campus_id
        }
      })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    changeEvent(val, fieldName) {
      this.submitted = false;
      this.$set(this, fieldName, val);
      if (fieldName == "academic_year") {
        this.isLoading = true;
        this.class_id = 0;
        this.selectedClass = 0;
        this.classArr = [];
        this.getClass(val);
      }
    },

    async getGradingDetail() {
      try {
        const response = await axios.get("/exam/detail", {
          params: {
            campus_id: store.state.user.campus_id,
            exam_id: this.$route.params.exam_id
          }
        })
        if (response.data[0] != undefined) {
          this.gradingInfo = response.data[0];
          this.gradeData = response.data;
          this.selectedYear = this.gradingInfo.academic_year;
          this.exam_name = this.gradingInfo.exam_name;
          this.exam_start_date = this.gradingInfo.exam_start_date;
          this.exam_end_date = this.gradingInfo.exam_end_date;
          this.selectedClass = this.gradingInfo.class_id;
          this.class_id = this.gradingInfo.class_id;
          this.academic_year = this.gradingInfo.academic_year;
          this.gradingSelected = this.gradingInfo.grading;
          await this.getClass(this.selectedYear);
          this.isLoading = false;
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        console.log("Exam Detail API Error", error);
        this.isLoading = false;
      }
    },

    async getClass(yearId) {
      try {
        const response = await axios.get("class/classList/" + yearId, {
          params: {
            campus_id: store.state.user.campus_id
          }
        });

        if (response.data) {
          let defaultSelectOption = [{
            'id': 0,
            'text': 'Select Class'
          }];

          this.classArr = defaultSelectOption.concat(response.data);
        }
      } catch (error) {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        console.error("Error fetching class list:", error);
      } finally {
        this.isLoading = false;
      }
    },

    getThisAcademicYear() {
      axios.get('period/getThisPeriod')
        .then(res => {
          this.academicYear = res.data;
          this.academic_year = this.academicYear.id;
          this.getClass(this.academic_year);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    confirmUpdate() {
      this.submitted = true;
      this.isLoading = true;
      if (this.exam_start_date && this.exam_end_date && new Date(this.exam_end_date) < new Date(this.exam_start_date)) {
        this.isError = true;
      } else {
        this.isError = false;
      }
      this.update();
    },

    update() {
      var request = {
        academic_year: this.academic_year,
        class_id: this.class_id,
        campus_id: store.state.user.campus_id,
        created_id: store.state.user.id,
        exam_name: this.exam_name,
        exam_id: this.$route.params.exam_id,
        exam_start_date: this.exam_start_date,
        exam_end_date: this.exam_end_date,
      }

      axios.post("/exam/update", request, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data.status == 200) {
            this.$notification.success("Exam Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });
            this.$router.push({
              name: "examList"
            });
          }
          else if (response.data.success == false) {
            this.isLoading = false;
            this.$notification.error(response.data.message, {
              timer: 3,
              position: "bottomCenter",
            });
          }
          if (response.data.status == 201) {
            this.isLoading = false;
            this.$notification.error("Exam Data Already Exist", {
              timer: 3,
              position: "bottomCenter",
            });
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          console.log("Exam Edit API Error", error.errors);
          this.isLoading = false;
        });
    },

    showList() {
      this.$router.push({
        path: "/exam/list"
      });
    },
  },

  mounted() {
    this.isLoading = true;
    this.getThisAcademicYear();
    this.getAcademicYear();
    this.getGradingDetail();
  },
};
