import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Select2Multiple from 'v-select2-multiple-component';
import CONFIG from "@/assets/js/config.js";
import store from '../../store';
import {
  mapGetters
} from "vuex";

export default {
  name: "StudentProfile",
  components: {
    MainLayout,
    Select2Multiple,
    Loading
  },

  data() {
    return {
      isLoading: false,
      currMonth: new Date().toLocaleString([], {
        month: 'long',
      }),
      studentInfo: {},
      parentInfo: {},
      latestNoticeInfo: {},
      timetableInfo: [],
      gradingInfoArr: {},
      exam_names_arr: {},
      attendanceInfoArr: {},
      noDataMsg: CONFIG.NO_DATA_EXIST,
      academicYearArr: null,
      religionArr: null,
      annualFeeArr: null,
      monthlyFeeArr: null,
      gradeArr: [],
      subjectArr: [],
      grade_name: '',
      isAdmin: true,
      day: null,
      timeTableMetas: [],
      classIds: [],
      collapseIds: [],
      gradeIds: [],
      total_annual: 0,
      total_monthly: 0,
      passFailResult: 0,
      classArr: null,
      year_id: 0,
      class_id: 0,
      requiredClass: false,
      requiredYear: false,
      academicYear: 0
    };
  },

  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },

  methods: {
    timetableDataMap(index) {
      const timetableMap = {};
      if (Array.isArray(this.timetableInfo[index])) {
        this.timetableInfo[index].forEach(item => {
          timetableMap[item.rowColIndex] = item.subject_name;
        });
      }
      return timetableMap;
    },

    getWeekDays() {
      axios.get('getWeekDays')
        .then(res => {
          this.day = res.data;
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getAcademicYear() {
      axios.get('getAcademicYear', {
        params: {
            campus_id: store.state.user.campus_id
        }
    })
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Select'
          }];
          this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },
    
    getReligion() {
      axios.get('getReligion')
        .then(res => {
          let $defaultSelectOption = [{
            'id': 0,
            'text': 'Choose Religion'
          }];
          this.religionArr = $defaultSelectOption.concat(res.data);
        }).catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    getStudentDetail() {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }

      if (this.$route.params.leavedStatus == 'active') {
        axios.get("/student/profile/" + this.$route.params.id, request)
          .then(response => {
            this.studentInfo = response.data;
            this.total_annual = this.studentInfo.classes.reduce((total, cls) => total+cls.annual_fee, 0);
            this.total_monthly = this.studentInfo.classes.reduce((total, cls) => total+cls.monthly_fee, 0);
            this.classIds = this.studentInfo.classes.map( classData => classData.class_id );
            this.gradeIds = this.studentInfo.classes.map( classData => classData.grade_id);
            this.collapseIds = Array.from({ length: this.studentInfo.classes.length }).fill(false);
            if (Object.keys(this.studentInfo).length > 0) {
              this.parentInfo = response.data.trashed_student.trashed_parent;
              this.getLatestNotice();
              this.getTimetableMeta();
              this.getTimetable();
              this.getSubject();
              this.getGrading();
              this.getAttendanceInfo(this.studentInfo.class_id);
              this.getGradeName();
              try {
                this.studentInfo.annual_fee = this.studentInfo.trashed_student.trashed_student_grade.trashed_campus_grade.trashed_grade_fee_master.annual_fee ?? '';
              } catch (e) {
                this.studentInfo.annual_fee = 0;
              }
              try {
                this.studentInfo.monthly_fee = this.studentInfo.trashed_student.trashed_student_grade.trashed_campus_grade.trashed_grade_fee_master.monthly_fee ?? '';
              }
              catch (e) {
                this.studentInfo.monthly_fee = 0;
              }
            }
            store.state.user.role == 1 ? this.isAdmin = true : this.isAdmin = false;
            this.isLoading = false;
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.isLoading = false;
            console.log("Active Student Detail API Error", error);
          });
      } else if (this.$route.params.leavedStatus == 'leaved') {
        axios.get("/student/leavedProfile/" + this.$route.params.id, request)
          .then(response => {
            if (response.data) {
              this.studentInfo = response.data;
              this.parentInfo = response.data.trashed_student.trashed_parent;
              this.classIds = this.studentInfo.classes.map( classData => classData.class_id );
              this.gradeIds = this.studentInfo.classes.map( classData => classData.grade_id);
              this.collapseIds = Array.from({ length: this.studentInfo.classes.length }).fill(false);
              this.getLatestNotice();
              this.getTimetableMeta();
              this.getTimetable();
              this.getSubject();
              this.getGrading();
              this.getAttendanceInfo();
              this.getGradeName();
              try {
                this.studentInfo.annual_fee = this.studentInfo.trashed_student.trashed_student_grade.trashed_campus_grade.trashed_grade_fee_master.annual_fee ?? '';
              } catch (e) {
                this.studentInfo.annual_fee = 0;
              }
              try {
                this.studentInfo.monthly_fee = this.studentInfo.trashed_student.trashed_student_grade.trashed_campus_grade.trashed_grade_fee_master.monthly_fee ?? '';
              }
              catch (e) {
                this.studentInfo.monthly_fee = 0;
              }
            }
          })
          .catch(error => {
            if (error.response && error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }              
            this.isLoading = false;
            console.log("Leaved Student Detail API Error", error);
          });
      }
    },

    getGradeName() {
      var acdYearId = this.studentInfo.trashed_class.trashed_campus_grade.acd_year_id;
      axios.get('getGradeNameByAcademicYear?id=' + acdYearId, {
          params: {
              campus_id: store.state.user.campus_id
          }
      }).then(res => {
          let $defaultSelectOption = [{
              "id": 0,
              "text": "All"
          }];
          this.gradeArr = $defaultSelectOption.concat(res.data);
      }).catch((error) => {
          if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
          }
          this.isLoading = false;
      });
  },

    getLatestNotice() {
      var request = {
        params: {
          class_id: this.studentInfo.class_id,
          class_student_id: this.$route.params.id,
          campus_id: store.state.user.campus_id
        }
      }

      axios.get("/notice/latest" , request)
        .then(response => {
          this.latestNoticeInfo = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }            
          this.isLoading = false;
          console.log("Latest Notice API Error", error);
        });
    },

    getGrading() {
      var request = {
        params: {
          student_id: this.studentInfo.student_id,
          class_ids: this.classIds,
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/grading/listForDetail" , request)
        .then(response => {
          this.gradingInfoArr = response.data;
          for(let i in this.gradingInfoArr) {
            const data = this.gradingInfoArr[i];
            this.exam_names_arr[i] = [...new Set(data.map(item => item.exam_name))];
          }
          
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response && error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }           
          this.isLoading = false;
          console.log("Grading API Error", error);
        });
    },

    getAttendanceInfo() {
      var request = {
        params: {
          class_student_id: this.$route.params.id,
          campus_id: store.state.user.campus_id,
          class_ids: this.classIds,
        }
      }
      axios.get("/studentAttendance/info", request)
      .then(response => {
        this.attendanceInfoArr = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Student Attendance API Error", error);
      });
    },

    getTimetableMeta() {
      var request = {
        params :{
          class_ids : this.classIds,
        }
      };
      axios.get("/timetableMeta/getTimetableMetas", request)
      .then((response) => {
        var data = response.data[0];
        for(let i in data) {
          this.timeTableMetas.push({days: data[i][0], time: data[i][1]})
        }
        this.isLoading = false;
      })
      .catch((error) => {
        if (error.response && error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
        console.log("Timetable Meta API Error", error);
      });
    },

    getTimetable() {
      axios.get("/timetable/detailForClasses", {
        params: {
          class_ids: this.classIds,
          campus_id: store.state.user.campus_id
        }
      })
        .then(response => {
          this.timetableInfo = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Timetable API Error", error);
        });
    },

    getSubject() {
      var request = {
        params: {
          grade_ids: this.gradeIds,
          campus_id: store.state.user.campus_id,
          acd_year_id: this.studentInfo.trashed_class.trashed_campus_grade.acd_year_id
        }
      }
      axios.get("/timetable/subjectBygrade", request)
        .then(response => {
          this.subjectArr = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }            
          this.isLoading = false;
          console.log("Subject API Error", error);
        });
    },

    confirmDelete(id, studentId, parentId) {
      if (window.confirm("Are you sure you want to delete?")) {
        this.deleteStudent(id, studentId, parentId);
      }
    },

    deleteStudent(id, studentId, parentId) {
      var request = {
        params: {
          student_id: studentId,
          campus_id: store.state.user.campus_id
        }
      }
      axios.delete("/student/delete/" + id + '/' + parentId, request)
        .then(response => {
          if (response.data.success == true) {
            this.showList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Delete Error->", error);
        });
    },

    confirmActivate(id, parentId) {
      if (window.confirm("Are you sure you want to activate?")) {
        this.activateStudent(id, parentId);
      }
    },

    activateStudent(id, parentId) {
      axios.delete("/student/activate/" + id + '/' + parentId)
        .then(response => {
          if (response.data.success == true) {
            this.showList();
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
          console.log("Student Activate Error->", error);
        });
    },

    showList() {
      this.$router.push({
        path: "/student/list"
      });
    },

    back() {
      this.$router.go(-1);
    },

    showNoticeList() {
      this.$router.push({
        path: "/notice/list"
      });
    },

    showAddPayment() {
      this.$router.push({
        name: "paymentCreate"
      });
    },

    showEdit(id) {
      this.$router.push({
        name: "studentEdit",
        params: {
          id: id
        }
      });
    },

    getMaxGradeByCampus() {
      var request = {
        params: {
          campus_id: store.state.user.campus_id
        }
      }
      axios.get("/grade/getMaxGradeIdByCampus", request).then((response) => {
          this.maxGradeId = response.data;
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    showAttendanceDetail() {
      this.$router.push({
        name: "attendanceStudentDetail"
      });
    },
    
    showModel(id, result) {
      this.currentId = id;
      this.passFailResult = result;
      this.$bvModal.show('modal-1');
    },
    
    getClass($yearId) {
      axios.get("class/classList/" + $yearId , {
        params: {
            campus_id: this.$store.state.user.campus_id,
        }
      })
      .then(res => {
        let $defaultSelectOption = [{
          'id': 0,
          'text': 'Select Class'
        }];
        this.classArr = $defaultSelectOption.concat(res.data);
        this.isLoading = false;
      }).catch((error) => {
        if (error.response.status == 401) {
          this.$store.commit('logout');
          window.location.replace('login');
        }
        this.isLoading = false;
      });
    },
    
    changeEvent(val, fieldName) {
      this.$set(this, fieldName, val);
      if (fieldName == 'class_id') {
        this.proceed = false;
      }
      if (fieldName == 'year_id') {
        this.proceed = false;
        this.class_id = 0;
        this.academicYear =this.academicYearArr.find(option => option.id === Number(this.year_id));
      }
    },
    
    editStudentPassFail(id, result) {
      var msg = result == 1 ? "Are you sure you want to update this student pass?" : 
                              "Are you sure you want to update this student fail?";
      if (window.confirm(msg)) {
        this.clickDisabled = true;
        var request = {
          update_class_student_id: id,
          campus_id: store.state.user.campus_id,
          updated_id: store.state.user.id,
          result: result
        }

        axios.post("/student/edit/passFail", request, {
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(response => {
            if (response.data.success == true) {
              this.clickDisabled = false;
              this.getStudentDetail();
              this.isLoading = false;
              var successMsg = result == 1 ? "Setting Student Pass Successfully" : "Setting Student Fail Successfully";
              this.$notification.success(successMsg, {
                timer: 3,
                position: "bottomCenter"
              });
            } else {
              this.clickDisabled = false;
              this.$notification.error(response.data.message, {
                timer: 3,
                position: "bottomCenter"
              });
            }
          })
          .catch(error => {
            if (error.response.status == 401) {
              this.$store.commit('logout');
              window.location.replace('login');
            }
            this.clickDisabled = false;
            this.isLoading = false;
            console.log("Setting Student Pass/Fail API Error", error.errors);
          });
      } else {
        this.clickDisabled = false;
      }
    },
    
    showEditToPass(id, result, classId, yearId) {
      this.proceed = true;
      if (classId == 0 && this.proceed) {
        this.requiredClass = true;
      } else {
        this.requiredClass = false;
      }
      if (yearId == 0 && this.proceed) {
        this.requiredYear = true;
      } else {
        this.requiredYear = false;
      }
      if (this.requiredClass == false && this.requiredYear == false) {
        if (result == 1) {
          this.$router.push({
            name: "studentPass",
            params: {
              id: id
            },
            query: {
              class_id: classId,
              year_id: yearId
            }
          });
        } else {
          this.$router.push({
            name: "studentFail",
            params: {
              id: id
            },
            query: {
              class_id: classId,
              year_id: yearId
            }
          });
        }
      }
    },

    showEditToFail(id) {
      this.$router.push({
        name: "studentFail",
        params: {
          id: id
        }
      });
    },

    downloadPdfForQrCode() {
      this.isLoading = true;
      this.gradeArr.forEach((value) => {
        if (value.id == this.studentInfo.trashed_class.trashed_campus_grade.grade_id) {
          this.grade_name = value.text;
        }
      });
      axios.get("/student/downloadPdf/" + this.$route.params.id, {
          params: {
            campus_id: store.state.user.campus_id,
            student_name: this.studentInfo.trashed_student.name,
            guardian1_name: this.parentInfo ? this.parentInfo.guardian1_name : '',
            class_name: this.studentInfo.trashed_class.section,
            grade_name: this.grade_name
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob' // responseType is a sibling of headers, not a child
        })
        .then(response => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var currentDate = new Date();
            const fileName = `qrcode_${this.studentInfo.trashed_student.name}_${currentDate.getFullYear()}${(currentDate.getMonth() + 1) > 9 ? '' : '0'}${currentDate.getMonth()+1}${(currentDate.getDate()) > 9 ? '' : '0'}${currentDate.getDate()}.pdf`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.isLoading = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })     
    },
    downloadPdfForStudentInfo(classStuentId) {
      this.isLoading = true;
      axios.get("/student/downloadPdfForStudentInfo/" + classStuentId + '/' + this.studentInfo.trashed_class.trashed_campus_grade.academic_period.start_date + '/' + store.state.user.campus_id, {
          params: {
            campus_id: store.state.user.campus_id
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob' // responseType is a sibling of headers, not a child
        })
        .then(response => {
          if (response.status == 200) {
            var currentDate = new Date();
            const fileName = `reportcard_${this.studentInfo.trashed_student.name}_${currentDate.getFullYear()}${(currentDate.getMonth() + 1) > 9 ? '' : '0'}${currentDate.getMonth()+1}${(currentDate.getDate()) > 9 ? '' : '0'}${currentDate.getDate()}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.isLoading = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },
    
    downloadCertificatePdfForStudent(classStuentId) {
      this.isLoading = true;
      axios.get("/student/downloadCertificatePdf/" + classStuentId, {
        params: {
          campus_id: store.state.user.campus_id
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob' // responseType is a sibling of headers, not a child
      })
        .then(response => {
          if (response.status == 200) {
            var currentDate = new Date();
            const fileName = `certificate_${this.studentInfo.trashed_student.name}_${currentDate.getFullYear()}${(currentDate.getMonth() + 1) > 9 ? '' : '0'}${currentDate.getMonth() + 1}${(currentDate.getDate()) > 9 ? '' : '0'}${currentDate.getDate()}.pdf`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.isLoading = false;
          }
        })
        .catch(error => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },

    toggleCollapse(content) {
      for(let i = 0; i < this.collapseIds.length; i++) {
        if (i !== content) {
          this.collapseIds[i] = false;
        }
      }
      this.$set(this.collapseIds, content, !this.collapseIds[content]);
    },
  },

  mounted() {
    this.isLoading = true;
    this.getStudentDetail();
    this.getMaxGradeByCampus();
    // this.getWeekDays();
    
    this.getReligion();
    this.getAcademicYear();
  },
};
