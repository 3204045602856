export default Object.freeze ({
    NO_DATA_FOUND : 'No Data Found !',
    NO_DATA_EXIST : 'There is no data.',
    IMAGE_TOO_LARGE : 'Image too large!',
    OTHER_LOGIN_FAILED : 'Other Role Login Failed',
    IMAGE_MAX_FILE_SIZE : 4194304,
    IMAGE_MAX_SIZE_ERROR: 'Only maximun 4Mb imges size is allowed.',
    APPLICATION_URL : process.env.VUE_APP_URL,
    ENABLE_QR : {
        DISABLE : 0,
        ENABLE : 1
    },
    TEACHER_PARENT_CHAT : {
        DISABLE: 0,
        ENABLE : 1
    },
    ROLE : {
        ADMIN : 1,
        STAFF : 2,
        OTHER : 3
    },
    CHAT_REQUEST_STATUS : {
        PENDING : 0,
        APPROVED : 1,
        REJECTED : 2
    },
    APPLICATION : {
        TYPE_TXT : {
            1 : "Parent",
            2 : "Student"
        },
        TYPE : {
            PARENT : 1,
            STUDENT : 2,
        },
    },

    ITEM_TYPES: {
        TYPE_TXT : {
            1: "Parent Item",
            2: "Student Item",
            3: "Additional Item",
        },
        TYPE : {
            PARENT : 1,
            STUDENT : 2,
            ADDITIONAL : 3,
        }
    },
    INPUT_TYPES: {
        1: 'Text',
        2: 'Textarea',
        3: 'Radio',
        4: 'Check Box',
        5: 'Select Box',
        6: 'File',
    },
    INPUT_TYPES_VAL: {
        TEXT: 1,
        TEXTAREA: 2,
        RADIO: 3,
        CHECKBOX: 4,
        SELECTBOX: 5,
        FILE: 6,
    },
    PARENT_REQUIRED: [
        'guardian1_name',
        'password',
        'phone1',
        'address',
    ],
    STUDENT_REQUIRED: [
        'name',
        'dob',
        'gender',
        'password',
        'religion',
        'class_id',
    ],
    STUDENT_ADDITIONAL_REQUIRED: [
        'phone1',
        'address',
    ],
    PAYMENT_STATUS : {
        PENDING : 0,
        APPROVED : 1,
        REJECTED : 2,
    },
    ADDITIONAL_STATUS: {
        OVERWRITE: 1,
        KEEP: 2,
        CREATE: 3
    },
    BOOK_TYPE: {
        1: 'Physical Book',
        2: 'Ebook'
    },
    BOOK_TYPE_VALUE: {
        'Physical Book': 1,
        'Ebook': 2
    },

    LIBRARY_MEMBER_STATUS:{
        1: 'Active',
        2: 'InActive',
        3: 'Block',
    },

    FILE_NAME_MAX_FILE_SIZE:  5242880 ,

    FILE_NAME_MAX_SIZE_ERROR: 'File size exceeds the maximum allowed limit of 5MB.',

})
