import MainLayout from "@/components/layout/admin/MainLayout.vue";
import AdvancedLaravelVuePaginate from 'advanced-laravel-vue-paginate';
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Select2Multiple from 'v-select2-multiple-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";
import store from '@/store';

export default {
    name: "ApplicantList",
    components: {
        MainLayout,
        Select2Multiple,
        flatPickr,
        AdvancedLaravelVuePaginate,
        Loading
    },

    data() {
        return {
            noDataMsg: CONFIG.NO_DATA_FOUND,
            appTypeText: CONFIG.APPLICATION.TYPE_TXT,
            appUrl: CONFIG.APPLICATION_URL.replace(/\/$/, '') + '/',
            isLoading: false,
            search_status: false,
            applicantData: {},
            searchData: {
                student_name: '',
                class_id: null,
                form_title: '',
                start_date: '',
                end_date: '',
                academic_year: null,
                form_type: null,
                status: null
            },
            academicYearArr: null,
            classArr: null,
            FormTypeArr: null,
            passFailDisable: false,
            checkAll: false,
            nextPageCheckAll: false,
            selectedCb: [],
            searchSelectedCb: [],
            unSelectedCb: [],
        };
    },

    methods: {
        getAcademicYear() {
            axios.get('getAcademicYear', {
                params: {
                    campus_id: store.state.user.campus_id
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select'
                    }];
                    this.academicYearArr = $defaultSelectOption.concat(res.data.academicYearArr);
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getThisAcademicYear() {
            this.isLoading = true;
            axios.get('period/getThisPeriod')
                .then(res => {
                    this.academicYear = res.data;
                    this.getClass(this.academicYear.id)
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getClass($yearId) {
            axios.get("class/classList/" + $yearId, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    let $defaultSelectOption = [{
                        'id': 0,
                        'text': 'Select Class'
                    }];
                    this.classArr = $defaultSelectOption.concat(res.data);
                    this.isLoading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getApplicationFormType() {
            axios.get('getFormType')
                .then(res => {
                    this.FormTypeArr = res.data;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        getApplicantList(page = 1) {
            axios.get('applicant/list?page=' + page, {
                params: {
                    campus_id: this.$store.state.user.campus_id,
                }
            })
                .then(res => {
                    this.applicantData = res.data;
                    this.isLoading = false;
                    this.search_status = false;
                    this.idArray = [];
                    this.applicantData.data.forEach(e => {
                        this.idArray.push(e.id);
                    });
                    if (this.checkAll || this.nextPageCheckAll) {
                        this.unSelectedCb = new Set(this.unSelectedCb);
                        let removedArr = this.idArray.filter((name) => {
                            return !this.unSelectedCb.has(name);
                        });

                        this.selectedCb = this.selectedCb.concat(removedArr);
                        this.selectedCb = [...new Set(this.selectedCb)];
                        this.idArray = this.idArray.concat(this.idArray);
                        this.idArray = [...new Set(this.idArray)];
                        this.unSelectedCb = [...new Set(this.unSelectedCb)];
                    }
                }).catch((error) => {
                    if (error.response && error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                })
        },

        searching(page = 1) {
            this.isLoading = true;
            var request = {
                params: {
                    student_name: this.searchData.student_name,
                    class_id: this.searchData.class_id,
                    form_title: this.searchData.form_title,
                    start_date: this.searchData.start_date,
                    end_date: this.searchData.end_date,
                    academic_year: this.searchData.academic_year,
                    form_type: this.searchData.form_type,
                    status: this.searchData.status,
                    campus_id: this.$store.state.user.campus_id
                }
            }
            axios.get('applicant/search?page=' + page, request)
                .then((res) => {
                    this.isLoading = false;
                    if (res.data) {
                        this.applicantData = res.data;
                        this.isLoading = false;
                        this.search_status = true;
                        this.idArray = [];
                        this.applicantData.data.forEach(e => {
                            this.idArray.push(e.id);
                        });
                        if (this.checkAll || this.nextPageCheckAll) {
                            this.unSelectedCb = new Set(this.unSelectedCb);
                            let removedArr = this.idArray.filter((name) => {
                                return !this.unSelectedCb.has(name);
                            });
                            this.searchSelectedCb = this.searchSelectedCb.concat(removedArr);
                            this.searchSelectedCb = [...new Set(this.searchSelectedCb)];
                            this.idArray = this.idArray.concat(this.idArray);
                            this.idArray = [...new Set(this.idArray)];
                            this.unSelectedCb = [...new Set(this.unSelectedCb)];
                        }
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                });
        },

        reset() {
            this.searchData = [];
        },

        selectAll() {
            this.selectedCb = [];
            if (!this.checkAll) {
                if (this.search_status) {
                    this.searchSelectedCb = this.idArray;
                    this.nextPageCheckAll = true;
                    this.checkAll = true;
                } else {
                    this.selectedCb = this.idArray;
                    this.nextPageCheckAll = true;
                    this.checkAll = true;
                }
            } else {
                this.checkAll = false;
                this.nextPageCheckAll = false;
                this.selectedCb = [];
                this.searchSelectedCb = [];
            }
        },

        updateCheckall(e, sId) {
            let maxCbTotal = this.applicantData.total;
            if (!e.target.checked) {
                this.unSelectedCb = this.unSelectedCb.concat(sId);
                this.unSelectedCb = [...new Set(this.unSelectedCb)];
            } else {
                const filtersList = this.unSelectedCb.filter(element => element !== sId);
                this.unSelectedCb = filtersList;

                this.unSelectedCb = [...new Set(this.unSelectedCb)];
            }
            // if allcheck is clicked and then single uncheck ( checkAll is false and nexPage is all checked )
            if (!this.nextPageCheckAll) {
                if (this.search_status) {
                    if (maxCbTotal == this.searchSelectedCb.length) {
                        this.checkAll = true;
                    } else {
                        this.checkAll = false;
                    }
                } else {
                    if (maxCbTotal == this.selectedCb.length) {
                        this.checkAll = true;
                    } else {
                        this.checkAll = false;
                    }
                }
            } else {
                if (maxCbTotal == this.applicantData.total - this.unSelectedCb.length) {
                    this.checkAll = true;
                } else {
                    this.checkAll = false;
                }
            }
        },

        searchApplicant() {
            this.isLoading = true;
            this.searchSelectedCb = [];
            this.checkAll = false;
            this.unSelectedCb = [];
            this.idArray = [];
            this.nextPageCheckAll = false;
            this.searching();
        },

        showDetail(studentId) {
            this.$router.push({
                name: "applicantDetail",
                params: {
                    id: studentId,
                }
            });
        },

        confirm(applicantIdArr, status) {
            var msg = status == 2 ? 'Are you sure you want to approve?' : 'Are you sure you want to reject?';
            if (window.confirm(msg)) {
                this.checkApplicantData(applicantIdArr, status);
            }
        },
        
        checkApplicantData(applicantIdArr, status) {
            var request = {
                applicant_ids: applicantIdArr,
                result: status,
                campus_id: store.state.user.campus_id,
                created_id: store.state.user.id
            }
            if (applicantIdArr.length == 0) {
                this.isLoading = false;
                this.passFailDisable = false;
                alert('Please select data.');
                return;
            }
            axios.post("/applicant/check", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(response => {
                if (response.data.success == false) {
                    if (window.confirm(response.data.msg)) {
                        this.setApproveReject(applicantIdArr, status, 1); //newly overwrite
                    } else {
                        this.setApproveReject(applicantIdArr, status, 2); //keep
                    }
                } else {
                    this.setApproveReject(applicantIdArr, status, 3);
                }
            })
        },

        setApproveReject(applicantIdArr, result, status) {
            this.proceed = true;
            this.passFailDisable = true;
            this.isLoading = true;
            var request = {
                applicant_ids: applicantIdArr,
                result: result,
                campus_id: store.state.user.campus_id,
                created_id: store.state.user.id,
                addtional_status: status
            }
            axios.post("/applicant/setApproveReject", request, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(response => {
                    if (response.status == 204) {
                        this.submitted = false;
                        this.getSection(this.grade, {
                            params: {
                                campus_id: store.state.user.campus_id
                            }
                        });
                        this.passFailDisable = false;
                        this.isLoading = false;
                        return;
                    }
                    if (response.data.success == true) {
                        this.isLoading = false;
                        this.passFailDisable = false;
                        this.checkAll = false;
                        this.selectedCb = [];
                        this.searchSelectedCb = [];
                        if (this.search_status) {
                            this.searchApplicant();
                        } else {
                            this.getApplicantList();
                        }
                        var resMsg = result == 2 ? "Setting Student Approved Successfully" : "Setting Student Rejected Successfully";
                        this.$notification.success(resMsg, {
                            timer: 3,
                            position: "bottomCenter"
                        });
                    } else {
                        this.isLoading = false;
                        this.passFailDisable = false;
                        alert(response.data.msg);
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    this.passFailDisable = false;
                    console.log("Student Approve/Reject API Error", error.errors);
                });
        },
    },

    mounted() {
        this.isLoading = true;
        this.getAcademicYear();
        this.getThisAcademicYear();
        this.getApplicantList();
        this.getApplicationFormType();
    },
};
