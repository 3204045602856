import MainLayout from "@/components/layout/admin/MainLayout.vue";
import axios from "axios";
import CONFIG from "@/assets/js/config.js";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import store from '@/store';

export default {
    name: "bookDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            book: [],
            isLoading: false,
            campusId: store.state.user.campus_master.campus_code,
            noDataMsg: CONFIG.NO_DATA_EXIST,
            config: CONFIG,
            bookTypeOptions: CONFIG.BOOK_TYPE,
        };
    },

    methods: {
        getDetail() {
            axios.get(`book/detail/${this.$route.params.id}`)
                .then(res => {
                    if (res.data) {
                        this.book = res.data;
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    this.isLoading = false;
                    console.log("Book Detail API Error", error);
                })
        },

        getFormattedDescription(description) {
            return description ? description.replace(/\n/g, '<br>') : '';
        }
    },
    computed: {
        formattedDescription() {
            if (this.book.description) {
                // Replace newline characters with <br> tags
                return this.book.description.replace(/\n/g, '<br>');
            }
            return '';
        },
        showFileNameField() {
            return this.book.type === 2;
        }
    },

    mounted() {
        this.isLoading = true;
        this.getDetail();
    },
};
