import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import CONFIG from "@/assets/js/config.js";
import axios from "axios";
import {
  required,
  maxLength
} from "vuelidate/lib/validators";

export default {
  name: "GradeEdit",
  components: {
    MainLayout,
    Loading
  },

  data() {
    return {
      noDataMsg: CONFIG.NO_DATA_EXIST,
      isLoading: false,
      submitted: false,
      gradeData: {}
    };
  },

  validations: {
    gradeData: {
      grade_name: {
        required,
        maxLength: maxLength(50)
      },
    }

  },

  methods: {
    getDetail() {
      this.isLoading = true;
      axios.get('grade/detail/' + this.$route.params.id)
        .then((res) => {
          if (res.data) {
            this.gradeData = res.data;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        });
    },

    update() {
      this.submitted = true;
      if (this.$v.$invalid) {
        return;
      }
      this.gradeData.login_id = this.$store.state.user.id;
      axios.post(`grade/update/${this.$route.params.id}`, this.gradeData)
        .then(res => {
          if (res.data.success) {
            this.$notification.success("Grade Updated Successfully", {
              timer: 3,
              position: "bottomCenter"
            });

            this.$router.push({
              name: "gradeList"
            });
          } else if (res.status == 201) {
            this.$notification.error(res.data.message, {
              timer: 3,
              position: "bottomCenter"
            });
          }
        }).catch(error => {
          if (error.response.status == 400) {
            this.$notification.error("There is duplicate data for grade.", {
              timer: 3,
              position: "bottomCenter"
            });
          }
          if (error.response.status == 401) {
            this.$store.commit('logout');
            window.location.replace('login');
          }
          this.isLoading = false;
        })
    },
  },

  mounted() {
    this.getDetail();
  },
};
