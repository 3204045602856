import MainLayout from "@/components/layout/admin/MainLayout.vue";
import Loading from 'vue-loading-overlay';
import axios from 'axios';
import CONFIG from "@/assets/js/config.js";

export default {
    name: "libraryMemberDetail",
    components: {
        MainLayout,
        Loading
    },

    data() {
        return {
            isLoading: false,
            libraryMemberInfo: {},
            noDataMsg: CONFIG.NO_DATA_EXIST,
            lmstatus: CONFIG.LIBRARY_MEMBER_STATUS,
        };
    },

    methods: {
        getLibraryMemberDetail() {
            // Convert the route parameter to an integer
            const memberId = parseInt(this.$route.params.id, 10);
        
            // Check if the ID is a valid positive integer
            if (!Number.isInteger(memberId) || memberId <= 0) {
                this.isLoading = false;
                console.log("Invalid Library Member ID");
                this.$notification.error("Invalid Library Member ID provided.", {
                    timer: 3,
                    position: "bottomCenter",
                });
                return;
            }
        
            // Proceed with the API call if the ID is valid
            this.isLoading = true; // Set loading state before the API call
            axios.get(`libraryMember/detail/${memberId}`)
                .then(response => {
                    this.libraryMemberInfo = response.data;
                })
                .catch(error => {
                    // Handle errors, including unauthorized access
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('logout');
                        window.location.replace('login');
                    }
                    console.log("LibraryMember Detail API Error", error);
                })
                .finally(() => {
                    // Reset the loading state after the API call
                    this.isLoading = false;
                });
        },        

        showList() {
            this.$router.push({
                path: "/libraryMember/list"
            });
        },
    },
    mounted() {
        this.isLoading = true;
        this.getLibraryMemberDetail();
    },
};
